@font-face {
  font-family: pixelify;
  src: url("PixelifySans-Regular.4275cf34.ttf") format("truetype");
}

* {
  -webkit-user-select: none;
  user-select: none;
}

a, a:visited {
  z-index: 1;
  color: #fff;
  position: relative;
}

body {
  padding: 0;
  padding-bottom: env(safe-area-inset-bottom);
  color: #fff;
  background: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100svh;
  margin: 0;
  font-family: pixelify, sans-serif;
  display: flex;
  overflow: hidden;
}

#game {
  position: relative;
}

#score {
  text-shadow: 0 0 .5rem #000;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

canvas {
  background: #fff;
}

h3, p {
  margin: 0;
}

section {
  margin: 1rem 0;
}

#bottom {
  padding: 1rem;
}

.touch {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.touch .movement {
  flex-direction: row;
  flex: 1;
  display: flex;
}

.touch .jump, .touch .left, .touch .right {
  flex: 1;
}

#restart {
  z-index: 1;
  margin-top: 1rem;
  font-family: pixelify, sans-serif;
  position: relative;
}

@media screen and (width <= 640px) {
  canvas {
    max-width: 100%;
    max-height: 100%;
  }
}

/*# sourceMappingURL=index.46bd908d.css.map */
