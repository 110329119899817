@font-face {
	font-family: "pixelify";
	src: url("assets/fonts/pixelify/PixelifySans-Regular.ttf") format("truetype");
}

* {
	user-select: none;
}

a, a:visited {
	position: relative;
	z-index: 1;
	color: #ffffff;
}

body {
	margin: 0;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100svh;
	overflow: hidden;

	background: #000000;
	padding-bottom: env(safe-area-inset-bottom);

	font-family: "pixelify", sans-serif;
	color: #ffffff;
}

#game {
	position: relative;
}

#score {
	position: absolute;
	top: 1rem;
	right: 1rem;

	font-size: 2rem;
	text-shadow: 0 0 0.5rem #000000;
}

canvas {
	background: #ffffff;
}

h3, p {
	margin: 0;
}

section {
	margin: 1rem 0;
}

#bottom {
	padding: 1rem;
}

.touch {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
}

.touch .movement {
	flex: 1;

	display: flex;
	flex-direction: row;
}

.touch .jump {
	flex: 1;
	/*				background: rgba(255, 0, 0, 0.5);*/
}

.touch .left {
	flex: 1;
	/*				background: rgba(0, 255, 0, 0.5);*/
}

.touch .right {
	flex: 1;
	/*				background: rgba(0, 0, 255, 0.5);*/
}

#restart {
	position: relative;
	z-index: 1;
	font-family: "pixelify", sans-serif;
	margin-top: 1rem;
}

@media screen and (max-width: 640px) {
	canvas {
		max-width: 100%;
		max-height: 100%;
	}
}
